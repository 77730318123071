(function (window) {
  window.__env = window.__env || {};

  window.__env.domain = 'DOMAIN';
  window.__env.cookieDomain = 'COOKIE';
  window.__env.baseUrl = 'BASEURL';
  window.__env.baseVersion = '/v1';
  window.__env.apiVersion = '/api/v6/';
  window.__env.protocol = 'PROTOCOL';
  window.__env.socketProtocol = 'SOCKET';
  window.__env.clientName = 'CLIENTNAME';
  window.__env.clientEnv = CLIENTENV;
}(this));
